var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"app-main__inner"},[_c('div',{staticClass:"container mb-3 dm-sans-font"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-between align-items-center mb-3"},[_c('div',[_c('div',{staticClass:"flex justify-between align-items-center space-x-3 mb-3"},[_c('span',{staticClass:"text-sm md:text-2xl font-semibold text-gray-900"},[_vm._v(_vm._s(_vm.$t("viewProductDetail")))])])]),_c('div',[_c('span',{staticClass:"text-base font-semibold text-blue-900 cursor-pointer",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t("back")))])])]),_c('div',{staticClass:"p-4 bg-white"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',{staticClass:"mt-2 font-semibold",staticStyle:{"font-size":"21px"}},[_vm._v(" "+_vm._s(_vm.currentArticle.description)+" ")]),_c('div',{staticClass:"bg-white single-product-img mt-3 cursor-pointer",on:{"click":_vm.showImages}},[(_vm.currentArticle.images)?_c('el-carousel',{attrs:{"interval":5000,"indicator-position":"none"}},_vm._l((_vm.currentArticle.images),function(item,i){return _c('el-carousel-item',{key:i},[_c('span',[_c('img',{staticClass:"carousel-cell-image",attrs:{"src":item.image,"data-zoomable":""}})])])}),1):_vm._e()],1),_c('div',{staticClass:"flex justify-center mt-2"},[_c('button',{staticClass:"focus:outline-none mr-5 px-4 py-2 flex justify-center btn-yellow rounded",attrs:{"disabled":_vm.prevNum < 1 && _vm.num < 1},on:{"click":_vm.addProductCart}},[_vm._v(" Beställ ")]),_c('input-number',{attrs:{"num":_vm.num},on:{"onchange":_vm.onChangeInput}})],1)]),_c('div',{staticClass:"col-md-5 mt-5 mb-10"},[_c('div',{staticClass:"bg-white flex flex-col space-y-10"},[(
								_vm.currentArticle.product_long_description !==
								'<p>nan</p>'
							)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(
									_vm.cleanData(
										_vm.currentArticle.product_long_description
									)
								)}})]):_c('div')]),(_vm.articleVariationList.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"bg-white variations mt-5"},[_c('product-variation-list',{attrs:{"products":_vm.articleVariationList}})],1)])]):_vm._e()]),_c('div',{staticClass:"col-md-3 flex flex-col justify-between"},[_c('div',{staticClass:"flex flex-col space-y-3 bg-white mt-5"},[_c('div',[_c('h2',{staticClass:"text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.currentArticle.description)+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("articleNumber"))+": "+_vm._s(_vm.currentArticle.article_number)+" ")]),(
								_vm.GET_COMPANY_SETTING.hide_stock_quantity ===
									false &&
								_vm.hidden_fields.stock_quantity === false
							)?_c('div',{staticClass:"flex space-x-4 items-center"},[_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("disposableQty"))+": "+_vm._s(_vm.currentArticle.quantity_in_stock))])]):_vm._e(),_c('br'),(_vm.hidden_fields.delivery_time === false)?_c('p',{staticClass:"italics"},[_c('i',[_vm._v(_vm._s(_vm.$t("Delivery"))+": "+_vm._s(_vm.currentArticle.delivery_time)+" "+_vm._s(_vm.$t("days")))])]):_vm._e(),(
								_vm.currentArticle.estimated_delivery_date !==
									'' &&
								_vm.hidden_fields.delivery_time === false
							)?_c('p',{staticClass:"italics"},[_c('i',[_vm._v(_vm._s(_vm.$t("estimatedDeliveryDate"))+": "+_vm._s(_vm.currentArticle.estimated_delivery_date))])]):_vm._e(),(!_vm.currentArticle?.has_discount)?_c('div',[_c('p',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.parseAmount(_vm.currentArticle.sales_price))+":- ")])]):_c('div',[_c('p',{staticClass:"price !text-red-600"},[_vm._v(" "+_vm._s(_vm.applyDiscount( _vm.parseAmount( _vm.currentArticle.sales_price ), _vm.currentArticle?.discount_value, _vm.currentArticle?.discount_type === "PERCENT" ))+" :- ")])]),(_vm.currentArticle?.has_discount)?_c('div',[_c('p',{staticClass:"priceSlash !text-black"},[_c('del',[_vm._v(_vm._s(_vm.parseAmount( _vm.currentArticle.sales_price ))+":-")])]),(_vm.currentArticle?.has_discount)?_c('p',{staticClass:"priceDiscount !text-black"},[_vm._v(" "+_vm._s(_vm.$t("discounted"))+" "),_c('span',{staticClass:"font-black !text-red-500 text-[.8rem] font-bold"},[_vm._v(" "+_vm._s(_vm.convertDiscountValueToPercentage( _vm.currentArticle?.discount_value, _vm.currentArticle?.discount_type, _vm.currentArticle.sales_price ))+"%")])]):_vm._e()]):_vm._e(),(
								_vm.currentArticle.hasOwnProperty(
									'standard_price'
								)
							)?_c('div',[_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.$t("standard_price"))+": "+_vm._s(_vm.currentArticle.standard_price))])])]):_vm._e(),(
								_vm.currentArticle.has_customer_resale_price &&
								_vm.hidden_fields.recommended_price === false
							)?_c('div',{staticStyle:{"margin-bottom":"12px"}},[_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.currentArticle.consumer_resale_field_title))])]),_c('div',{staticClass:"position-relative"},[_c('p',{staticClass:"customer-resale-price font-semibold text-blue-500"},[_vm._v(" "+_vm._s(_vm.currentArticle.consumer_resale_amount)+":- ")])])]):_vm._e(),(_vm.currentArticle.is_paired_product)?_c('div',[_c('div',{staticClass:"m4"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.$t("productPackageText"))+": "+_vm._s(_vm.currentArticle.product_package_bundle_number))])])]):_vm._e(),(_vm.currentArticle.has_customer_resale_price)?_c('div',[_c('div',{},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.$t("profitMargin"))+": "+_vm._s(_vm.calculateProfitMargin()))])])]):_vm._e(),(_vm.currentArticle.product_pdf)?_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"text-base text-blue-600"},[_c('a',{staticClass:"flex",attrs:{"href":_vm.currentArticle.product_pdf,"target":"_blank","download":""}},[_c('img',{staticClass:"cursor-pointer flex w-full",staticStyle:{"height":"18px","width":"18px"},attrs:{"src":require("../../assets/images/icons/file.svg"),"alt":_vm.$t('productPdf')}}),_c('span',[_vm._v(_vm._s(_vm.$t("productPdf")))])])])]):_vm._e()])])]),_c('div',{staticClass:"flex flex-col mt-5"},[_c('h2',{staticClass:"text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t("specification"))+" ")]),_c('hr',{staticClass:"border-4 border-blue-800"}),_vm._l((_vm.GET_SINGLE_ARTICLE.specifications),function(item,key){return _c('div',{key:key,staticClass:"grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-2 mt-2",class:key % 2 === 1 ? 'bg-gray' : ''},[_c('p',{staticClass:"text-gray-500"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"text-gray-500"},[_vm._v(_vm._s(item.text))])])}),(
						_vm.GET_SINGLE_ARTICLE.hasOwnProperty(
							'article_accessories'
						) &&
						_vm.GET_SINGLE_ARTICLE.hasOwnProperty(
							'recommended_article'
						)
					)?_c('div',[(
							_vm.GET_SINGLE_ARTICLE.article_accessories.length >
								0 ||
							_vm.GET_SINGLE_ARTICLE.recommended_article.length >
								0
						)?_c('hr',{staticClass:"border-4 border-blue-800 mb-3"}):_vm._e()]):_vm._e()],2),(
					_vm.GET_SINGLE_ARTICLE.hasOwnProperty(
						'article_accessories'
					) &&
					_vm.GET_SINGLE_ARTICLE.hasOwnProperty('recommended_article')
				)?_c('div',[(
						_vm.GET_SINGLE_ARTICLE.article_accessories.length > 0 ||
						_vm.GET_SINGLE_ARTICLE.recommended_article.length > 0
					)?_c('div',{staticClass:"row"},[_c('div',{class:{
							'col-md-7':
								_vm.GET_SINGLE_ARTICLE.article_accessories
									.length > 0 &&
								_vm.GET_SINGLE_ARTICLE.recommended_article
									.length > 0,
							'col-md-12':
								_vm.GET_SINGLE_ARTICLE.article_accessories
									.length <= 0 &&
								_vm.GET_SINGLE_ARTICLE.recommended_article
									.length > 0,
						}},[_c('div',{staticClass:"bg-white recommend-br"},[_c('div',{staticClass:"recommended-product-headline"},[_c('p',{staticClass:"headline-text"},[_vm._v(" "+_vm._s(_vm.$t("recommendedProduct"))+" ")])]),_c('recommended-product',{attrs:{"products":_vm.GET_SINGLE_ARTICLE.recommended_article,"user_type":'buyer'}})],1)]),_c('div',{class:{
							'col-md-5':
								_vm.GET_SINGLE_ARTICLE.article_accessories
									.length > 0 &&
								_vm.GET_SINGLE_ARTICLE.recommended_article
									.length > 0,
							'col-md-12':
								_vm.GET_SINGLE_ARTICLE.article_accessories
									.length > 0 &&
								_vm.GET_SINGLE_ARTICLE.recommended_article
									.length <= 0,
						}},[_c('div',{staticClass:"bg-white recommend-br"},[_c('div',{staticClass:"recommended-product-headline"},[_c('p',{staticClass:"headline-text"},[_vm._v(" "+_vm._s(_vm.$t("accessories"))+" ")])]),_c('recommended-product',{attrs:{"products":_vm.GET_SINGLE_ARTICLE.article_accessories,"user_type":'buyer'}})],1)])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }